@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

p {
  font-family: 'Josefin Sans';
}

body {
  margin: 0;
  font-family: 'Josefin Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f7f7;
}

.focus {
  outline: none !important;
  box-shadow: none !important;
}

code {
  font-family: 'Josefin Sans';
}

.contenedor {
  padding: 10px;
}

.expositores {
  padding: 50px 200px
}

.cronogramas {
  padding: 50px 150px
}



@media only screen and (max-width: 992px) {
  .divider {
    display: none;
  }

  .expositores {
    padding: 50px 25px;
  }

  .cronogramas {
    padding: 15px 5px;
  }
}

#bannerHomeContenedor{
  background-color: #dddeda;
}
#bannerHomeTitulo .titulo1{
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}
#bannerHomeTitulo .subtitulo1{
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-style: normal;
}
#bannerHomeColumnaIzquierdaTexto{
  font-family: "Roboto", sans-serif;
}
#bannerHomeTitulo {
  background-color: #8e8583;
  position: relative;
  padding: 15px 15px;
}
#bannerHomeTitulo .titulo1,
#bannerHomeTitulo .subtitulo1{
  font-weight: bold;
  color: white; 
  text-align: left;
  margin: 0;
}
#bannerHomeTitulo .simbolo{
  font-weight: bold;
  color: #b08372;
  margin: 0;
}
.unBoton {
  border-radius: 20px;
  background-color: #b08372; 
  border-color: #b08372;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

@media only screen and (min-width: 992px) {

  #bannerHomeContenedor{
    min-height: 550px;
  }
  #bannerHomeColumnaIzquierdaTitulo{
    padding-left: 0px;
  }
  
  #bannerHomeColumnaIzquierdaTexto{
    margin-top: 300px;
    margin-left: 15px;
    margin-right: 15px;
  }
  #bannerHomeColumnaDerecha{
    background-image: url('../images/banner.jpg');
    background-size: cover;
  }
  #bannerHomeTitulo {
    position: absolute;
    padding: 55px 55px;
    margin-top: 55px;
    opacity: 0.9;
    min-width: 55%;
  }
  #bannerHomeTitulo p {
    opacity: 1;
  }

}

.block-icon {
  position: relative;
  display: inline-flex;
}

.icon-tag {
  position: absolute;
  top: -20px;
  right: -8px;
  width: 50px !important;
  height: 50px !important;
  font-size: 30px;
  border-radius: 50%;
  background-color: #8e8583 !important;
  color: "white";
}

.icon-bar {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
}

/* Style the icon bar links */
.icon-bar a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 30px;
}

/* Style the social media icons with color, if you want */

.facebook {
  background: #b08372;
  color: white;
  border-top-right-radius: 35px;
  border-top-left-radius: 35px;
}

.instagram {
  background: #b08372;
  color: white;
}

.youtube {
  background: #b08372;
  color: white;
  border-bottom-right-radius: 35px;
  border-bottom-left-radius: 35px;
}